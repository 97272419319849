<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">404</h1>
            <h4 class="pt-3">{{aLang.p404_error}}</h4>
            <b-row>
              <b-alert show variant="secondary">
                {{aLang.c_back}}
                <a class="ml-2 alert-link mr-2 link" @click="fn_goBack()">{{aLang.p404_backPage}}!</a>
                <a class="alert-link link" @click="fn_goHome()">{{aLang.c_home}}!</a>
              </b-alert>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import LanguageService from '../../service/LanguageService';
export default {
  name: "Page404",
  data() {
    return {
      aLang: LanguageService.lang,      
    };
  },
  methods: {
    fn_goBack() {
      this.$router.go(-1);
    },
    fn_goHome() {
      this.$router.push({ name: "Admin-Home" });
    }
  }
};
</script>
